// 刘志坤 V2.5.3
<template>
  <div>
    <a-upload
      v-model:file-list="state.fileList"
      name="file"
      :action="state.action + '?width=88&height=88'"
      withCredentials
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <a-button class="btn" v-if="state.fileList && state.fileList.length < state.count">
        <img
          style="margin-right: 4px; vertical-align: middle; transform: translateY(-1px);"
          src="@/assets/svg/upload.svg"
        />
        上传文件
      </a-button>
    </a-upload>
    <p class="tips">{{ state.uploadText || '（可上传图片、文档等类型文件，单个文件大小不能超过5M）' }}</p>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onUpdated } from 'vue'
// import { Notification } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
export default defineComponent({
  emits: ['fileChange'],
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    count: Number,
    fileType: {
      type: Array,
      default: () => ['jpg', 'png', 'tif', 'jpeg', 'bmp', 'webp']
    },
    action: {
      type: String,
      default: action
    },
    size: {
      type: Number,
      default: 10
    },
    minSzieByte: {
      type: Number,
      default: 6
    },
    uploadText: String
  },
  setup(props, ctx) {
    console.log('props', props)
    const { emit } = ctx
    const state = reactive({
      fileList: [], // 图片list
      label: computed(() => props.label),
      action: computed(() => props.action), // 伤处路径
      count: computed(() => props.count), // 最大上传几个
      uploadText: computed(() => props.uploadText),
      size: computed(() => props.size), // 最大尺寸
      fileType: computed(() => props.fileType) // 上传文件类型
    })
    const beforeUpload = file => {
      return new Promise((resolve, reject) => {
        const suffix = file.name.split('.').pop()
        const size = Math.floor(file.size / (1024 * 1024))
        let type = state.fileType
        if (!state.fileType) type = ['jpg', 'png', 'tif', 'jpeg', 'bmp']
        if (state.size && size > state.size) {
          // Notification['error']({
          //   message: '提示',
          //   description: `大小不能超过${state.size}M`
          // })
          cmsNotice('error', `大小不能超过${state.size}M`)
          return reject(false)
        }
        if (file.size <= props.minSzieByte) {
          console.log(file.size, 'size')
          cmsNotice('error', `大小应大于${props.minSzieByte}个字节`)
          return reject(false)
        }
        if (!type.includes(suffix)) {
          // Notification['error']({
          //   message: '提示',
          //   description: `格式错误，仅支持${type.join(',')}格式`
          // })
          cmsNotice('error', `格式错误，仅支持${type.join(',')}格式`)
          return reject(false)
        }
        return resolve(true)
      })
    }
    const handleChange = info => {
      console.log('info', info)
      try {
        const {
          file: { status, response }
        } = info
        switch (status) {
          case 'uploading':
            break
          case 'done':
            // 处理上传成功

            if (response && response.code === 0) {
              console.log('state.fileList', state.fileList)
              emit('fileChange', 'add', state.fileList)
            }
            break
          case 'removed':
            // 处理错误
            emit('fileChange', 'remove', state.fileList)
            break
        }
      } catch (error) {
        console.log('err', error)
      }
    }

    const transformFile = file => {
      const fileData = new FormData()
      fileData.append('width', 120)
      fileData.append('file', file)
      fileData.append('height', 120)
      console.log(file)
      return fileData
    }

    onUpdated(() => {
      console.log('props.fileList', props.fileList)
      if (props.fileList.length && !state.fileList.length) {
        state.fileList = props.fileList
      }
    })

    return {
      beforeUpload,
      handleChange,
      state,
      transformFile
    }
  }
})
</script>

<style lang="scss" scoped>
.btn {
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  color: #666666;
  &:hover {
    color: #666666;
  }
}
.tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 17px;
  width: 550px;
  margin-top: 8px;
}
</style>
